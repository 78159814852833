import React, { Component } from 'react';
import  { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import './header.css';
// import styled from 'styled-components';
import { FaCuttlefish } from 'react-icons/fa';

import { Words, Arwes, Line, Project, Heading, Link } from 'arwes';

class Header extends Component {
  constructor(props)
  {
    super(props);
  }
	render() {
    	return (
			
			<Navbar bg="transparent" variant="dark" className="nav-style" expand="lg">
				<Navbar.Brand href="/" className="header-icon"><FaCuttlefish /></Navbar.Brand>
				<Navbar.Toggle/>
					<Navbar.Collapse className="justify-content-end">
						<Nav>
							<Nav.Item>
								<Link className="header-link" href="#intro">About</Link>
							</Nav.Item>
							<Nav.Item>
								<Link className="header-link" href="#projects">Projects</Link>
							</Nav.Item>
							<Nav.Item>
								<Link className="header-link" href="#experience">Experience</Link>
							</Nav.Item>
							<Nav.Item>
								<Link className="header-link" href="#contact">Contact</Link>
							</Nav.Item>	
						</Nav>
					</Navbar.Collapse>
			</Navbar>
		)
	}
}


export default Header;
