import React, { Component } from 'react';
import './about.css';
import  { Button } from 'react-bootstrap';

class About extends Component {
    constructor(props)
    {
        super(props);
    }
    render() {
        return (
            <div id="about">
                <div className="about-main">
                    <h1 className="about-title">About Me</h1>
                    <h2 className="about-text">
                        I'm Chris    
                    </h2>
                </div>
            </div>
        )
    }
}

export default About;
