import React, { Component } from 'react';
import './experience.css';
import { Button, Row, Col } from 'react-bootstrap';
import { FaExternalLinkAlt } from 'react-icons/fa';
import {MdDoNotDisturbAlt} from 'react-icons/md';

import { Words, Arwes, Line, Project, Heading } from 'arwes';

class Experience extends Component {
    constructor(props)
    {
        super(props);
    }
    render() {
        return (
            
            <div id="experience">
                <div className='experience-main'>
                    <Heading className="experience-main-title" node='h1'>Experience</Heading>
                    <Line animate />
                    <Row>
                        <Col sm={12}>
                            <a className="project-block" href="#">
                            <Project
                                    animate
                                    header='Software Engineer'
                                    headerSize='h5'

                                >
                                {anim => (
                                    <>
                                    <p><Words animate show={anim.entered} layer="secondary">
                                        Chase
                                    </Words></p>
                                    <p><Words animate show={anim.entered} layer="secondary">
                                        java, python, powershell, javascript, react
                                    </Words></p>
                                    </>
                                )}
                            </Project>
                            </a>
                        </Col>
                    </Row>
                    <br />

                    <Row>
                        <Col sm={12}>
                            <a className="project-block" href="#">
                            <Project
                                    animate
                                    header='Operations Engineer'
                                    headerSize='h5'

                                >
                                {anim => (
                                    <>
                                    <p><Words animate show={anim.entered} layer="secondary">
                                        Chase
                                    </Words></p>
                                    <p><Words animate show={anim.entered} layer="secondary">
                                        operations
                                    </Words></p>
                                    </>
                                )}
                            </Project>
                            </a>
                        </Col>
                    </Row>
                    <br />

                    <Row>
                        <Col sm={12}>
                            <a className="project-block" href="#">
                            <Project
                                    animate
                                    header='Algorithmic Trader'
                                    headerSize='h5'

                                >
                                {anim => (
                                    <>
                                    <p><Words animate show={anim.entered} layer="secondary">
                                        Other
                                    </Words></p>
                                    <p><Words animate show={anim.entered} layer="secondary">
                                        tradingview, tos, trader workstation
                                    </Words></p>
                                    </>
                                )}
                            </Project>
                            </a>
                        </Col>
                    </Row>
                    <br />
                
                </div>

            </div>
        )
    }
}

export default Experience;
