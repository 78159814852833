import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Header from './components/header';
import Footer from './components/footer';

import Intro from './components/intro';
import About from './components/about';
import Projects from './components/projects';
import Experience from './components/experience';
import Contact from './components/contact';

import  { Button, Container, Row, Col } from 'react-bootstrap';

import { ThemeProvider, createTheme, withStyles, Arwes } from 'arwes';


export default class App extends Component {
  render() {
    return (
      <>
                  <div className="main">

          <ThemeProvider theme={createTheme()}>
          <Arwes
            animate
          >

            <Container fluid>
            <Header />

                <div className="App">
                  <Intro />
                  <Projects />
                  <Experience />
                  <Contact />
                </div>

              <Footer />
            </Container>

            </Arwes>
            </ThemeProvider>
            </div>




      </>
    )
  }
}
