import React, { Component } from 'react';
import './contact.css';
import  { Row, Col } from 'react-bootstrap';
import { Words, Arwes, Line, Project, Heading, Button } from 'arwes';

class Contact extends Component {
    constructor(props)
    {
        super(props);
    }
    render() {
        return (
            <div>
                <div id="contact">
                    <Row>
                        <Col sm={12}>
                            <Heading className="contact-title" node='h1'>Contact</Heading>
                            <Line animate />
                            <h3>always looking to connect!</h3>
                            <br />
                            <a href="mailto:clee86@gmail.com"><Button className="intro-button" animate>Get in Touch</Button></a>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default Contact;
