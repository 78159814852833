import React, { Component, useState } from 'react';
import  { Button, Container, Row, Col, Modal, Carousel } from 'react-bootstrap';
import { FaExternalLinkAlt } from 'react-icons/fa';

import backtest1 from '../img/backtest1.jpg';
import backtest2 from '../img/backtest2.jpg';

import { Words, Arwes, Line, Project } from 'arwes';


function Backtester() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
      <>
          <a className="modal-link" onClick={handleShow}>
          <Project
            animate
            header='Backtester'
            headerSize='h5'

        >
            {anim => (
                <>
                <p><Words animate show={anim.entered} layer="secondary">
                    test tool for multiple timeframes, historical data, indicators, p&l, etc.
                </Words></p>
                <p><Words animate show={anim.entered} layer="secondary">
                    built in django, sqlite, python, pandas, numpy, alpaca
                </Words></p>
                </>
            )}
        </Project>
          </a>
          <Modal 
              show={show} 
              onHide={handleClose}
              size="lg"
          >
              <Modal.Header closeButton>
                  <Modal.Title>Backtest</Modal.Title>
              </Modal.Header>
              <Modal.Body className="show-grid">
                  <Row>
                      <Col xs={12} md={12}>
                          <Carousel>

                              <Carousel.Item>
                                  <img className="backtests" src={backtest1} alt="backtest-1"></img>
                                  <p />
                                  <Carousel.Caption>
                                      <h3>Screenshot 1</h3>
                                      <p>Python trader.</p>
                                  </Carousel.Caption>
                              </Carousel.Item>

                              <Carousel.Item>
                                  <img className="backtests" src={backtest2} alt="backtest-2"></img>
                                  <p />
                                  <Carousel.Caption>
                                      <h3>Screenshot 1</h3>
                                      <p>Python trader.</p>
                                  </Carousel.Caption>
                              </Carousel.Item>

                          </Carousel>
                      </Col>
                  </Row>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="primary" onClick={handleClose}>
                      Close
                  </Button>
              </Modal.Footer>
          </Modal>
      </>
  );
}
export default Backtester;