import React, { Component, useState } from 'react';
import  { Button, Container, Row, Col, Modal, Carousel } from 'react-bootstrap';
import './projects.css';

import { FaExternalLinkAlt } from 'react-icons/fa';
import { CgWebsite } from 'react-icons/cg';
import { BsTools } from 'react-icons/bs';
import { IoMdApps } from 'react-icons/io';
import {MdDoNotDisturbAlt} from 'react-icons/md';

import AlgorithmicTrader from './algorithmic-trader';
import Backtester from './backtester';
import Crypto from './cryptocurrency';
import AacApp from './aacapp';

import { Words, Arwes, Line, Project, Heading } from 'arwes';

class Projects extends Component {
    constructor(props)
    {
        super(props);
    }
    render() {
        return (
            <div id="projects">
                <Heading className="projects-main-title" node='h1'>Projects</Heading>
                <Line animate />
                <Row>
                    <Col sm={12}>
                        <a className="project-block" href="https://www.candlestickspatterns.com">
                        <Project
                            animate
                            header='Candlestick Patterns'
                            headerSize='h5'
                        >
                            {anim => (
                                <>
                                <p><Words animate show={anim.entered} layer="secondary">
                                    candlesticks, interactive candlestick patterns, technical analysis, and charts.
                                </Words></p>
                                <p><Words animate show={anim.entered} layer="secondary">
                                    built in react, javascript, node.js, apexcharts, aws, docker
                                </Words></p>
                                </>
                            )}
                        </Project>
                    </a>
                    </Col>
                </Row>
                <br />
                
                <Row>
                    <Col sm={12}>
                        <AlgorithmicTrader />
                    </Col>
                </Row>
                <br />

                <Row>
                    <Col sm={12}>
                        <Backtester />
                    </Col>
                </Row>
                <br />

                <Row>
                    <Col sm={12}>
                        <Crypto />
                    </Col>
                </Row>
                <br />

                <Row>
                    <Col sm={12}>
                    <a className="project-block"  href="#">
                    <Project
                            animate
                            header='Watchlists'
                            headerSize='h5'

                        >
                            {anim => (
                                <>
                                <p><Words animate show={anim.entered} layer="secondary">
                                    pulls real-time data and displays custom stock watchlists for equities, futures, forex.
                                </Words></p>
                                <p><Words animate show={anim.entered} layer="secondary">
                                    built in react, Javascript, node.js, python
                                </Words></p>
                                </>
                            )}
                        </Project>
                        </a>
                    </Col>
                </Row>
                <br />           

                <Row>
                    <Col sm={12}>
                        <AacApp />
                    </Col>
                </Row>
                <br />
            </div>
        )
    }
}

export default Projects;
