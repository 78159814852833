import React, { Component } from 'react';
import  { Navbar, Nav, Button, Row, Col } from 'react-bootstrap';

import { FaExternalLinkAlt } from 'react-icons/fa';
import { CgWebsite } from 'react-icons/cg';
import { BsTools } from 'react-icons/bs';
import { IoMdApps } from 'react-icons/io';

import {AiFillLinkedin, AiFillGithub, AiFillTwitterCircle} from 'react-icons/ai';
import { FaLinkedinIn, FaGithub, FaTwitter } from 'react-icons/fa';


import './footer.css';

class Footer extends Component {
  constructor(props)
  {
    super(props);
  }

  render() {

  return (
    <div className="footer">
      <Row>
        <Navbar fixed="bottom">
          <Navbar.Collapse>
              <Col xs={12}>
                <Nav className="nav-icons">
                  <a className='icon-1' href="https://www.linkedin.com/in/cleehb"><FaLinkedinIn/></a><br />
                  <a className='icon-2' href="https://github.com/cleecode"><FaGithub/></a>
                  <a className='icon-3' href="https://twitter.com"><FaTwitter/></a>

                </Nav>

              </Col>  
          </Navbar.Collapse>
          
          
          
          <Navbar.Collapse className="justify-content-end">
            <Nav >
              <p className="footer-chris">by chris - copyright 2020</p>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Row>
    </div>
    )
  }
}

export default Footer;
