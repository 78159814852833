import React, { Component, useState } from 'react';
import  { Button, Container, Row, Col, Modal, Carousel } from 'react-bootstrap';
import { FaExternalLinkAlt } from 'react-icons/fa';

import crypto1 from '../img/crypto.png';
import { Words, Arwes, Line, Project } from 'arwes';



function Cryptocurrency() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
      <>
          <a className="modal-link" onClick={handleShow}>
          <Project
            animate
            header='Cryptocurrency Charts'
            headerSize='h5'

        >
            {anim => (
                <>
                <p><Words animate show={anim.entered} layer="secondary">
                    cryptocurrency real-time pricing, changes, sorting.
                </Words></p>
                <p><Words animate show={anim.entered} layer="secondary">
                    built in javascript, react, node.js
                </Words></p>
                </>
            )}
        </Project>
          </a>
          <Modal 
              show={show} 
              onHide={handleClose}
              size="lg"
          >
              <Modal.Header closeButton>
                  <Modal.Title>Cryptocurrency Charts</Modal.Title>
              </Modal.Header>
              <Modal.Body className="show-grid">
                  <Row>
                      <Col xs={12} md={12}>
                          <Carousel>

                              <Carousel.Item>
                                  <img className="crypto" src={crypto1} alt="crypto-1"></img>
                                  <p />
                                  <Carousel.Caption>
                                      <h3>Screenshot 1</h3>
                                      <p>Python trader.</p>
                                  </Carousel.Caption>
                              </Carousel.Item>


                          </Carousel>
                      </Col>
                  </Row>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="primary" onClick={handleClose}>
                      Close
                  </Button>
              </Modal.Footer>
          </Modal>
      </>
  );
}
export default Cryptocurrency;