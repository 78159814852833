import React, { Component } from 'react';
import './intro.css';
import  { Row, Col } from 'react-bootstrap';
import Chris from '../img/chris2.jpg';

import { Words, Arwes, Line, Image, Heading, Button } from 'arwes';


class Intro extends Component {
    constructor(props)
    {
        super(props);
    }
    render() {
        return (
            <div className="intro-top">
                <Row>
                    <Col md={8} sm={12}>
                        <div className="intro-main">
                            <div id="intro">
                                <Heading className="intro-name" node='h1'><Words animate>christopher s. lee</Words></Heading>
                                <Line animate layer="control" />
                                <h2 className="intro-text-main">
                                    <Words animate layer="secondary">software engineer @ jpmorgan & chase.</Words> <br />
                                    <Words animate layer="secondary">living in san diego, california.</Words><p />
                                    <Words animate layer="secondary">building applications, designing websites, daytrading, algorithmic trading.</Words>
                                </h2>
                               
                                <br />
                                <a href="mailto:clee86@gmail.com"><Button className="intro-button" animate>Contact</Button></a>
                            </div>
                        </div>
                    
                    </Col>
                    <Col md={4} sm={12}>
                        <img className="intro-img" src={Chris} alt="chris"></img>
                        {/* <Image className="intro-img" animate resources={Chris}></Image>   */}
                    </Col>
                </Row>
                

            </div>
        )
    }
}

export default Intro;

